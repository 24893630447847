import React from "react";
import { styled } from "linaria/react";
import { Spin } from "antd";
import PrivateRoute from "../withPrivateRoute";

const InitalLoader = styled.div`
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Homepage = () => {
  return (
    <InitalLoader>
      <Spin size="large" className="loader" />
    </InitalLoader>
  );
};
export default PrivateRoute(Homepage);
